import { CURRENCY_SYMBOL } from 'configs';
import { useAppSelector } from 'hooks/redux';
import { useMemo } from 'react';
import { selectAccountUser } from 'store/accounts';

export const useCurrentUser = () => {
  const user = useAppSelector(selectAccountUser);

  if (!user) {
    throw new Error('user is not defined');
  }

  return useMemo(
    () => ({
      ...user,
      weight: user.weight ?? null,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      currencySymbol: CURRENCY_SYMBOL,
      fullName: [user.firstName, user.lastName].filter(Boolean).join(' '),
    }),
    [user],
  );
};
