import axios from 'axios';
import { isNumber } from 'lodash';
import { makeChangeLog, schemaChangeLogs } from 'modules/change-log';
import { i18nAppTranslator } from 'modules/i18n';
import { AppState } from 'store';
import { apiAppProxy, apiRtk, BASE_URL, RTK_TAGS } from 'utils/service';
import { TaskTag } from '../todo-task-categories';
import { apiTodoTasks } from '../todo-tasks';
import { ServiceUsers } from '../users';
import { logConfig } from './log';
import {
  API_AUTH,
  CreateLogInput,
  CurrentAppUser,
  JWT,
  schemaCurrentUserInfo,
  UpdateCurrentUserInfoInput,
} from './models';
export * from './models';

class RefreshTokenError extends Error {
  response: { status: number };

  constructor(message: string, status: number) {
    super(message);
    this.response = { status };
  }
}

class Service {
  async refreshToken(p: { token?: string; refreshToken?: string }) {
    const urlPrefix = `${BASE_URL}/api/proxy/`;
    const { data } = await axios.post<JWT['jwt']>(`${urlPrefix}${API_AUTH.REFRESH_TOKEN}`, p);

    const result = await fetch(`${urlPrefix}${API_AUTH.GET_CURRENT_USER}`, {
      headers: {
        Authorization: 'Bearer ' + data.token,
      },
    });

    if (result.ok) {
      return { data };
    }

    throw new RefreshTokenError('refresh-token', result.status);
  }

  async getCurrentUser() {
    return apiAppProxy.get<CurrentAppUser>(API_AUTH.GET_CURRENT_USER);
  }

  async generatePassword(data: { mobilePhone: string }) {
    return apiAppProxy.post(API_AUTH.GENERATE_PASSWORD, data);
  }

  async sendCode(data: { mobilePhone: string; code: string }) {
    return apiAppProxy.post<JWT>(API_AUTH.LOGIN, data);
  }

  async logout() {
    return apiAppProxy.post(API_AUTH.LOGOUT);
  }
  async loginWithSecret(input: Required<Components.Schemas.LoginSecretRequest>) {
    return apiAppProxy.post<Components.Schemas.LoginResponse>(API_AUTH.LOGIN_WITH_SECRET, input);
  }
  async patchCurrentUser(
    data: Pick<Components.Schemas.CurrentUserProfileResponse, 'mustFillHealthSurvey'>,
  ) {
    return apiAppProxy.patch(API_AUTH.PATCH_CURRENT_USER, {
      mustFillHealthSurvey: data.mustFillHealthSurvey,
    });
  }

  async updateCurrentUserInfo(input: UpdateCurrentUserInfoInput) {
    const payload = schemaCurrentUserInfo.cast(input, { stripUnknown: true });

    return apiAppProxy.patch(API_AUTH.PATCH_CURRENT_USER, payload);
  }

  async createLog(input: CreateLogInput) {
    const changes = schemaChangeLogs.cast(
      { fields: input.fields },
      { stripUnknown: true, assert: false },
    );
    return apiAppProxy.post(API_AUTH.INSERT_USER_PATIENT_ACTIVITY, {
      userPatientProfileID: input.userPatientProfileID,
      userEmployeeProfileID: input.userEmployeeProfileID,
      changes: JSON.stringify(changes),
      entryDate: new Date().toISOString(),
      remarks: i18nAppTranslator.tp('updates-by-patient'),
    });
  }
}

export const ServiceAccounts = new Service();

export const apiAccounts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    loginWithSecret: build.mutation({
      queryFn: async (input: Required<Components.Schemas.LoginSecretRequest>) => {
        try {
          const res = await ServiceAccounts.loginWithSecret(input);

          return res;
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    updateCurrentUserInfo: build.mutation({
      queryFn: async (input: UpdateCurrentUserInfoInput) => {
        try {
          const res = await ServiceAccounts.updateCurrentUserInfo(input);
          return res;
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    updateCurrentUserInfoWithLog: build.mutation<
      void,
      {
        initData: UpdateCurrentUserInfoInput;
        formData: UpdateCurrentUserInfoInput;
      }
    >({
      queryFn: async (input, { getState }) => {
        try {
          const state = getState() as AppState;

          const user = state.accounts.user;

          if (!user) throw new Error('user is required');

          const fields = await makeChangeLog(logConfig, {
            initData: input.initData,
            formData: input.formData,
            getDefinition: () =>
              ServiceAccounts.getCurrentUser().then(({ data }) => {
                return data;
              }),
            update: (formData) => {
              const weight = isNumber(input.initData.weight)
                ? input.initData.weight
                : input.formData.weight;

              const payload = {
                ...formData,
                weight,
              };

              return ServiceAccounts.updateCurrentUserInfo(payload);
            },
          });

          ServiceAccounts.createLog({
            userPatientProfileID: String(user.appUserID),
            userEmployeeProfileID: String(user.userEmployeeProfileID),
            fields,
          });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    renewalPrescription: build.mutation<void, void>({
      queryFn: async (_, { dispatch }) => {
        try {
          await dispatch(
            apiTodoTasks.endpoints.createAutoTodoTaskByTag.initiate(
              TaskTag.PatientAppAskPrescription,
            ),
          ).unwrap();
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.ACCOUNT, id: 'prescription' }],
    }),
    checkPrescriptionRequest: build.query<boolean, void>({
      queryFn: async (_, { getState }) => {
        const state = getState() as AppState;

        const user = state.accounts.user;

        try {
          if (!user) throw new Error('user is required');

          const response = await ServiceUsers.canRequestPrescription({
            userPatientProfileID: String(user.appUserID),
          });

          return { data: response.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: RTK_TAGS.ACCOUNT, id: 'prescription' }],
    }),
  }),
});
