import { isBefore } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { REGEXP_PATIENT_ID_NUMBER, REGEXP_PATIENT_PHONE } from 'utils/regexp';
import * as yup from 'yup';
import { InferType } from 'yup';
import { schemaChangeLog } from '../../modules/change-log';

const API = 'Accounts';

export const API_AUTH = {
  GENERATE_PASSWORD: `${API}/GeneratePassword`,
  LOGIN: `${API}/LoginWithCode`,
  LOGIN_WITH_SECRET: `${API}/LoginWithSecret`,
  LOGOUT: `${API}/Logout`,
  REFRESH_TOKEN: `${API}/RefreshToken`,
  GET_CURRENT_USER: `${API}/GetCurrentAppUser`,
  PATCH_CURRENT_USER: `${API}/PatchCurrentUser`,
  INSERT_USER_PATIENT_ACTIVITY: `${API}/InsertUserPatientActivity`,
};

export interface CurrentAppUser extends Components.Schemas.CurrentUserProfileResponse {}

export interface JWT {
  jwt: {
    token: string;
    refreshToken: string;
  };
  expires: string;
}

export interface UserPermissions {
  isAllowToViewApp: boolean;
  isAllowToAnswerSurvey: boolean;
  isAllowToSignDoc: boolean;
}

export enum CityIDs {
  NA = '777e8cb0-25c5-449f-83ec-a38a736842a0',
}

export const schemaCurrentUserInfo = yup.object({
  firstName: yup.string().typeError('rule-required').required('rule-required').trim().default(''),
  lastName: yup.string().typeError('rule-required').required('rule-required').trim().default(''),
  occupation: yup.string().typeError('rule-required').required('rule-required').trim().default(''),
  idNumber: yup
    .string()
    .typeError('rule-required')
    .required('rule-required')
    .matches(REGEXP_PATIENT_ID_NUMBER, 'rule-id-number')
    .default(null),

  genderID: yup.string().typeError('rule-required').required('rule-required').default(''),
  hmoID: yup.string().typeError('rule-required').required('rule-required').default(''),

  email: yup
    .string()
    .typeError('rule-required')
    .required('rule-required')
    .email('rule-email')
    .trim()
    .default(''),
  dateOfBirth: yup
    .string()
    .typeError('rule-required')
    .required('rule-required')
    .test('date-feature', 'rule-date-not-feature', (value) => {
      return isBefore(convertToDate(value), new Date());
    })
    .default(new Date().toISOString()),
  mobilePhone: yup
    .string()
    .typeError('rule-required')
    .required('rule-required')
    .matches(REGEXP_PATIENT_PHONE, 'rule-mobile-phone')
    .default(''),
  weight: yup
    .number()
    .typeError('rule-required')
    .typeError('rule-number')
    .required('rule-required')
    .min(50, 'rule-weight')
    .max(400, 'rule-weight-max')
    .default(0),
  height: yup
    .number()
    .typeError('rule-required')
    .typeError('rule-number')
    .required('rule-required')
    .min(80, 'rule-height')
    .default(0),
  cityID: yup
    .string()
    .typeError('rule-required')
    .required('rule-required')
    .test('correct-city', 'rule-city-na', (value) => {
      if (!value) return true;
      return value !== CityIDs.NA;
    })
    .default(''),
  address: yup.string().typeError('rule-required').required('rule-required').trim().default(''),
});

export type UpdateCurrentUserInfoInput = Partial<InferType<typeof schemaCurrentUserInfo>>;
export type CreateLogInput = {
  userPatientProfileID: string;
  userEmployeeProfileID: string;
  fields: InferType<typeof schemaChangeLog>[];
};
