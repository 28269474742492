import { createSelector } from '@reduxjs/toolkit';
import { apiDocuments, DOCUMENT_STATUS, getDocumentStatus } from 'services/documents';
import { apiNotebooks, SURVEY_TYPES } from 'services/notebooks';
import { AppState } from '../index';

const selectState = (state: AppState) => state.accounts;

export const selectAccountUser = createSelector(selectState, ({ user }) => {
  return user;
});
export const selectAccountUserPermissions = createSelector(selectState, ({ permissions }) => {
  return permissions;
});

export const selectAccountUserMustFillSurvey = createSelector(selectAccountUser, (user) => {
  return Boolean(user && user.mustFillHealthSurvey);
});
const selectSignDocuments = (state: AppState) =>
  apiDocuments.endpoints.getFormDocuments.select()(state);

const selectSourceNotebooks = (state: AppState) =>
  apiNotebooks.endpoints.getAllSurveys.select()(state);

export const selectAccountSurveysShouldAnswer = createSelector(
  selectSourceNotebooks,
  selectAccountUserMustFillSurvey,
  (sourceNotebooks, mustFillHealth) => {
    return (sourceNotebooks.data || []).filter((notebook) => {
      if ('type' in notebook && notebook.type === SURVEY_TYPES.HEALTH_SURVEY) {
        return mustFillHealth;
      }
      return true;
    });
  },
);

export const selectAccountUserUnsignedBadge = createSelector(selectSignDocuments, ({ data }) => {
  const unsigned = (data || []).filter(
    (item) => getDocumentStatus(item) === DOCUMENT_STATUS.NOT_SIGNED,
  ).length;

  return unsigned;
});

export const selectAccountStatuses = createSelector(selectState, ({ isLoading, isInit, error }) => {
  return { isLoading, isInit, error };
});
export const selectAccountInstalled = createSelector(
  selectState,
  ({ isInstalled, installLastDate, installDoNotAskAgain }) => {
    return { isInstalled, installLastDate, installDoNotAskAgain };
  },
);
export const selectAccountNotifications = createSelector(
  selectState,
  ({ notificationsDoNotAskAgain }) => {
    return { notificationsDoNotAskAgain };
  },
);
